import { Button, Form, Input, Popover } from 'antd';
import { callPostApi } from 'api/axios';
import { UPDATE_PASSWORD_RULES as RULES } from 'constants/formValidations';
import { navigate } from 'gatsby-link';
import React, { useCallback, useState } from 'react';
import { toast, toastTypes } from 'utils/toast';
import PasswordComplexityChecklist from './PasswordComplexityChecklist';

const passwordPopover = (value) => (
  <PasswordComplexityChecklist password={value} />
);

export default function PasswordForm({ email, action, token }) {
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const passwordValue = Form.useWatch('newPassword', form);

  const validateFields = useCallback(async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errors) {
      return false;
    }
  }, [form]);

  /**
   * Enable/disable the submit button based on if the form has been invalid,
   * its validation error statuses and the loading status of the async operation
   */
  async function updateSubmitStatus() {
    const isFormValid = await validateFields();
    const formHasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setAllowSubmit(isFormValid && !loading && !formHasErrors);
  }

  async function handleUpdatePassword() {
    setLoading(true);
    try {
      const password = passwordValue?.trim();
      const url = `/v2/api/update-password?token=${token}`;
      const { data } = await callPostApi(url, {
        action,
        password,
      });
      toast(toastTypes.SUCCESS, data.message);
      navigate('/login');
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form
      name="manage_password_form"
      className="manage_password_form"
      form={form}
      initialValues={{
        email,
      }}
      onFinish={handleUpdatePassword}
      onChange={updateSubmitStatus}
    >
      <Form.Item name="email">
        <Input size="large" disabled />
      </Form.Item>

      {/* New Password */}
      <Popover
        placement="rightTop"
        trigger="focus"
        content={() => passwordPopover(passwordValue)}
      >
        <Form.Item name="newPassword" rules={RULES.newPassword} validateFirst>
          <Input.Password
            size="large"
            placeholder="New Password"
            type="password"
          />
        </Form.Item>
      </Popover>

      {/* Confirm Password  */}
      <Form.Item
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={RULES.confirmPassword}
      >
        <Input.Password
          size="large"
          placeholder="Confirm Password"
          type="password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          disabled={!allowSubmit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          style={{
            width: '150px',
          }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
