import { Button, Typography } from 'antd';
import { navigate } from 'gatsby-link';
import React from 'react';

export default function InvalidToken({ supportEmail, showResend }) {
  const { Title, Paragraph } = Typography;
  return (
    <div className="centered">
      <Title className="header_text" style={{ fontSize: '18px' }}>
        Invalid Token or the Token has expired
      </Title>
      <Paragraph>please contact us at {supportEmail}</Paragraph>
      {showResend && (
        <Button type="primary" onClick={() => navigate('/forgot-password')}>
          Resend email
        </Button>
      )}
    </div>
  );
}
