import { Typography } from 'antd';
import React from 'react';
import FmIcon from '../../assets/style/icons/fairmatic-logo.svg';

export default function PasswordHeader({ title, message }) {
  return (
    <div className="password_container">
      <FmIcon style={{ fontSize: '48px' }} className="fm_logo" />
      <Typography className="password_title">{title}</Typography>
      {message && <Typography>{message}</Typography>}
    </div>
  );
}
