import { Spin, Typography } from 'antd';
import React from 'react';
import parseToken from '../../utils/parseToken';
import AuthLayout from '../auth/authLayout';
import InvalidToken from './InvalidToken';
import PasswordForm from './PasswordForm';
import PasswordHeader from './PasswordHeader';

const supportEmail = (
  <a href="mailto:support@fairmatic.com">support@fairmatic.com</a>
);

const defaultMessage = 'Please enter your new password';

export default function ManagePassword({ action = 'reset' }) {
  if (typeof window === 'undefined') {
    return null;
  }
  const search = new URLSearchParams(window.location.search);
  const token = search.get('token');
  const message = search.get('message') || defaultMessage;
  const isUpdate = action === 'reset';
  const showMessage = isUpdate ? message : '';
  const parsedToken = !!token && parseToken(token);
  const userEmail = parsedToken?.email;

  const title = isUpdate ? 'Update Password' : 'Set Password';

  if (typeof window === 'undefined') {
    // If this is a prerender, don't render anything as the token
    // will be available only on the client side
    return (
      <AuthLayout>
        <div className="centered">
          <Spin size="large" />
        </div>
      </AuthLayout>
    );
  }

  if (!parsedToken) {
    return <InvalidToken supportEmail={supportEmail} showResend={isUpdate} />;
  }

  return (
    <AuthLayout>
      <div className="centered">
        <PasswordHeader message={showMessage} title={title} />
        <PasswordForm email={userEmail} action={action} token={token} />
        <Typography>Any issues? contact us at {supportEmail}</Typography>
      </div>
    </AuthLayout>
  );
}
